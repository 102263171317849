import { motion } from 'framer-motion';
import styled, { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --border-radius: 16px;
    --padding-large: 18px 36px;
    --padding-medium: 10px 36px;
    --padding-small: 6px 12px;
  }
`;

const staticStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  ${staticStyles}
  position: relative;
  z-index: 1;
`;

const Wrapper = styled.div`
  ${staticStyles}
  max-width: 100%;
  position: relative;
  width: 100%;
  gap: 12px;
`;

const Title = styled.h1`
  font-size: 42px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  
  @media screen and (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Description = styled.p`
  font-size: 18px;
  max-width: 600px;
  text-align: center;
  color: ${({theme}) => theme.text_primary};

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const SkillsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: center;
  gap: 30px;
`;

const SkillCard = styled(motion.div)`
  width: 100%;
  max-width: 500px;
  background-color: ${({theme}) => theme.card};
  border: 0.1px solid #e88438;
  border-radius: var(--border-radius);
  padding: var(--padding-large);

  @media screen and (max-width: 768px) {
    max-width: 400px;
    padding: var(--padding-medium);
  }

  @media screen and (max-width: 500px) {
    max-width: 300px;
    padding: var(--padding-medium);
  }
`;

const SkillTitle = styled.h2`
  font-size: 28px;
  font-weight: 600;
  color: ${({theme}) => theme.text_secondary};
  margin-bottom: 20px;
  text-align: center;
`;

const SkillList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 20px;
`;

const SkillItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  font-size: 16px;
  color: ${({theme}) => theme.text_primary + '90'};
  border-radius: 12px;
  border: 1px solid ${({theme}) => theme.text_primary + '90'};
  padding: var(--padding-small);
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const SkillImage = styled.img`
  object-fit: contain;
  width: 24px;
  height: 24px;
`;

export { GlobalStyle, Container, Wrapper, Title, Description, SkillsContainer, SkillCard, SkillTitle, SkillList, SkillItem, SkillImage };