import React from 'react';
import { Modal } from 'antd';
import { useTheme } from 'styled-components';
import { Title, Date, Desc, Image, Tags, Tag, ButtonGroup, Button } from './StyledModalComponent';


const ProjectModal = ({ openModal, setOpenModal }) => {
    const { project } = openModal || {};
    const theme = useTheme();

    if (!project) return null;

    return (
        <Modal
            open={true}
            onCancel={() => setOpenModal({ state: false, project: null })}
            footer={null}
            centered={true}
            width="80%"
            styles={{
                content: {
                    borderRadius: "16px",
                    margin: "50px 12px",
                    minHeight: "min-content",
                    maxHeight: "85svh",
                    backgroundColor: theme.bg,
                    color: theme.text_primary,
                    padding: "20px",
                    overflowY: "auto"
                }
            }}
        >
            <div style={{display: "flex", justifyContent: "center", height: "50svh", marginTop: "30px"}}>
                <Image src={project.image} alt={project.title} />
            </div>

            <Title>{project.title}</Title>
            <Date>{project.date}</Date>
            <Tags>
                {project.tags.map((tag, index) => (
                    <Tag key={index}>{tag}</Tag>
                ))}
            </Tags>
            <Desc>{project.description}</Desc>
            <ButtonGroup>
                {project.github && <Button dull href={project.github} target="_blank" rel="noopener noreferrer">View Code</Button>}
                {project.webapp && <Button href={project.webapp} target="_blank" rel="noopener noreferrer">View Live App</Button>}
            </ButtonGroup>
        </Modal>
    );
};

export default React.memo(ProjectModal);