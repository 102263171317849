import './App.css';
import { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components'
import { mainTheme } from './utils/Theme';
import { BrowserRouter as Router } from 'react-router-dom';

import Navbar from './components/navbar/Navbar';
import Hero from './components/hero/Hero';
import Skills from './components/skills/Skills';
import Education from './components/education/Education';
import Experience from './components/experience/Experience';
import Projects from './components/projects/Projects';
import ProjectModal from './components/projectModal/ProjectModal';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(176, 230, 62, 0.15) 0%, rgba(167, 227, 41, 0) 50%), linear-gradient(141.27deg, rgba(240, 174, 31, 0) 50%, rgba(240, 174, 31, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 98.5%, 70% 100%, 50% 98.5%, 30% 100%, 0 98.5%);
`;

function App() {
  const [openModal, setOpenModal] = useState({ state: false, project: null });

  return (
    <ThemeProvider theme={mainTheme}>
      <Router>
        <Navbar />
        <Body>
          <Hero />
          <Wrapper >
            <Skills />
            <Experience />
          </Wrapper>            
          <Projects openModal={openModal} setOpenModal={setOpenModal} />
          <Wrapper>
            <Education />
            <Contact />
          </Wrapper>
          <Footer />
          { openModal.state && (
            <ProjectModal openModal={openModal} setOpenModal={setOpenModal} />
          )}
        </Body>
      </Router>
    </ThemeProvider>
  );
}

export default App;
