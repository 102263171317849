import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { skills } from '../../data/constants';
import { Container, Description, GlobalStyle, SkillCard, SkillImage, SkillItem, SkillList, SkillsContainer, SkillTitle, Title, Wrapper } from './StyledSkillComponent'

const SkillImageWithFallback = ({ src, alt }) => {
  const [error, setError] = React.useState(false);

  if (error) return null;

  return <SkillImage src={src} alt={alt} onError={() => setError(true)} />;
};

const Skills = memo(() => {
  
  return (
    <div id='skills'>
      <GlobalStyle />
      <Container id='skills' role="region" aria-label="Skills">
        <Wrapper>
          <Title>Skills</Title>
          <Description>
            Over the past few years, I've gained the following valuable skills:
          </Description>
          <SkillsContainer>
            {skills.map((item, index) => (
              <SkillCard
                initial={{ y: 100, opacity: 0, scale: 0.6}}
                whileInView={{y: 0, opacity: 1, scale: 1}}
                transition={{ duration: 0.8}}
                viewport={{ once: true, amount: 0.4 }}
                whileHover={{
                  scale: 1.05,
                }}
                key={index}>
                <SkillTitle>{item.title}</SkillTitle>
                <SkillList>
                  {item.skills.map((skill, skillIndex) => (
                    <SkillItem key={skillIndex}>
                      {skill.image && <SkillImageWithFallback src={skill.image} alt={skill.name} />}
                      {skill.name}
                    </SkillItem>
                  ))}
                </SkillList>
              </SkillCard>
            ))}
          </SkillsContainer>
        </Wrapper>
      </Container>
    </div>
  );
});

Skills.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default Skills;