import React from 'react'
import ProjectCards from '../cards/ProjectCard';
import { projects } from '../../data/constants';
import { CardContainer, Container, Description, Title, Wrapper } from './StyledProjectsComponent';

const Projects = ({openModal,setOpenModal}) => {

    return (
        <Container id="projects" aria-label="Projects">
            <Wrapper>
                <Title>
                    Projects
                </Title>
                <Description>
                    Here are some of my works.
                </Description>
                <CardContainer>
                    {projects.map(project => (
                        <ProjectCards project={project} openModal={openModal} setOpenModal={setOpenModal} />
                    ))}
                </CardContainer>
            </Wrapper>
        </Container>
    )
}

export default Projects