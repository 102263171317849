export const mainTheme = {
    bg:"#fff9f0",
    bgLight: "#FFFFFF",
    primary:"#e88438",
    text_primary:"#4d4d4d",
    text_secondary:"#e88438",
    card:"#fff9e8",
    card_light: '#e6e1c5',
    button:"#f08c56",
    white:"#FFFFFF",
    black:"#000000",
}