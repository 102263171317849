// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto Mono", monospace;;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

body {
  margin: 0px !important;
  padding: 0;
  font-family: "Roboto Mono", monospace;;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 80px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #222A35;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #575C66;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #626970;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAGA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;EACV,qCAAqC;AACvC;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB;EACtB,UAAU;EACV,qCAAqC;AACvC;;AAEA,UAAU;AACV;EACE,UAAU;EACV,YAAY;AACd;;AAEA,UAAU;AACV;EACE,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,oBAAoB;AACpB;EACE,mBAAmB;AACrB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  font-family: \"Roboto Mono\", monospace;;\n}\n\nhtml {\n  scroll-behavior: smooth;\n  scroll-padding-top: 80px;\n}\n\nbody {\n  margin: 0px !important;\n  padding: 0;\n  font-family: \"Roboto Mono\", monospace;;\n}\n\n/* width */\n::-webkit-scrollbar {\n  width: 4px;\n  height: 80px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: #222A35;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #575C66;\n  border-radius: 6px;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #626970;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
