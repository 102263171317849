import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Link as LinkR } from 'react-router-dom';
import portfolioLogo from '../../assets/kcfLogo.png'
import { Profile } from '../../data/constants';
import { MenuOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import { duration } from '@mui/material';

const Nav = styled.div`
    background-color: ${({ theme }) => theme.card_light};
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    @media (max-width: 960px) {
        trastion: 0.8s all ease;
    }
`;

const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1200px;
`;

const NavLogo = styled(LinkR)`
    width: 80%;    
    padding: 0 6px;
    display: flex;
    justify-content: start;
    align-items: center;
    text-decoration: none;
    @media (max-width: 640px) {
      padding: 0 0px;
    }
`;

const MobileIcon = styled.div`
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 50%);
        font-size: 1.8rem;
        cursor: pointer;
        color: ${({ theme }) => theme.text_primary}
    }
`;

const NavItems = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    aligin-items: center;
    gap: 32px;
    padding: 0 6px;
    list-style: none;
    @media screen and (max-width: 768px) {
        display:none;
    }
`

const NavLink = styled(motion.a)`
    color: ${({ theme }) => theme.text_primary};
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
`

const ButtonContainer = styled.div`
    width: 80%;  
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 6px;
    @media screen and (max-width: 768px) {
        display: none;
    }
`

const LinkedinButton = styled(motion.button)`
    border: 1.8px solid ${({ theme }) => theme.primary};
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    color: ${({ theme }) => theme.primary};
    height: 70%;
    // &:hover {
    //     background-color: ${({ theme }) => theme.primary};
    //     color: ${({ theme }) => theme.text_primary}
    // }

    @media screen and (max-width: 768px) { 
        font-size: 14px;
    }
`

const PortfolioLogo = styled.img`
    height: 5rem;
    width: 5rem;
    object-fit: cover;
`;

const Span = styled.span`
    padding: 0 4px;
    font-weight: bold;
    font-size: 18px;
    color: ${({ theme }) => theme.text_primary};
`;

const MobileMenu = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 16px;
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    padding: 12px 40px 24px 40px;
    background: ${({ theme }) => theme.card_light};
    transition: all 0.3s ease-in-out;
    transform: ${({ isOpen }) => isOpen ? 'translateY(0)' : "translateY(-100%)"};
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 15px 10px 0px rgba(0, 0, 0, 0.3);
    opacity: ${({ isOpen }) => isOpen ? "100%" : "0"};
    z-index: ${({ isOpen }) => isOpen ? "1000" : "-1000"};
    list-style: none;
`;

const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();

    return (
        <Nav>
            <NavContainer>
                <NavLogo to="/">
                    <PortfolioLogo alt='Portfolio' src={portfolioLogo} />
                    <Span>Portfolio</Span>
                </NavLogo>
                <MobileIcon onClick={() => setIsOpen(!isOpen)}>
                    <MenuOutlined />
                </MobileIcon>
                <NavItems>
                    <NavLink
                        whileHover={{
                            color: theme.primary,
                            scale: 1.05,
                            y: 10
                        }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ duration: 0.3 }} href='#about'>About</NavLink>
                    <NavLink
                        whileHover={{
                            color: theme.primary,
                            scale: 1.05,
                            y: 10
                        }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ duration: 0.3 }} href='#skills'>Skills</NavLink>
                    <NavLink
                        whileHover={{
                            color: theme.primary,
                            scale: 1.05,
                            y: 10
                        }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ duration: 0.3 }} href='#experience'>Experience</NavLink>
                    <NavLink
                        whileHover={{
                            color: theme.primary,
                            scale: 1.05,
                            y: 10
                        }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ duration: 0.3 }} href='#projects'>Projects</NavLink>
                    <NavLink
                        whileHover={{
                            color: theme.primary,
                            scale: 1.05,
                            y: 10
                        }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ duration: 0.3 }} href='#education'>Education</NavLink>
                    <NavLink
                        whileHover={{
                            color: theme.primary,
                            scale: 1.05,
                            y: 10
                        }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ duration: 0.3 }} href='#contact'>Contact</NavLink>
                </NavItems>

                {
                    isOpen && (
                        <MobileMenu isOpen={isOpen}>
                            <NavLink
                                href="#about"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                About
                            </NavLink>
                            <NavLink
                                href="#skills"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                Skills
                            </NavLink>
                            <NavLink
                                href="#experience"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                Experience
                            </NavLink>
                            <NavLink
                                href="#projects"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                Projects
                            </NavLink>
                            <NavLink
                                href="#education"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                Education
                            </NavLink>
                            <NavLink
                                href='#contact'
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                Contact
                            </NavLink>
                            <LinkedinButton
                                style={{
                                    padding: "10px 16px",
                                    background: `${theme.primary}`,
                                    color: "white",
                                    width: "max-content"
                                }}
                                onClick={() => {
                                    window.open(Profile.linkedin, "_blank", "noreferrer");
                                }}
                            >
                                Linkedin Profile
                            </LinkedinButton>
                        </MobileMenu>
                    )
                }

                <ButtonContainer>
                    <LinkedinButton
                        whileHover={{
                            backgroundColor: theme.primary,
                            color: theme.text_primary,
                            scale: 1.05,
                            y: 10
                        }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ duration: 0.5 }}
                        onClick={() => {
                            window.open(Profile.linkedin, "_blank", "noreferrer");
                        }}>Linkedin Profile</LinkedinButton>
                </ButtonContainer>
            </NavContainer>
        </Nav>
    )
}

export default Navbar