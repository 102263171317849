import { motion, useScroll, useTransform } from 'framer-motion';
import React from 'react'
import styled, { useTheme } from 'styled-components'

const Document = styled.img`
    display: none;
    height: 70px;
    width: fit-content;
    background-color: #000;
    border-radius: 10px;
    &:hover{
        cursor: pointer;
        opacity: 0.8;
    }
`

const Description = styled.div`
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    color: ${({ theme }) => theme.text_primary};
    margin-bottom: 10px;
    @media only screen and (max-width: 768px){
        font-size: 12px;
    }
`

const Span = styled(motion.span)`
  display: -webkit-box;
  max-width: 100%;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: pre-line;
`;

const Card = styled(motion.div)`
  width: 100%;
  max-width: 650px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 0.1px solid ${({ theme }) => theme.primary};
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;

  @media only screen and (max-width: 768px){
    padding: 10px;
    gap: 8px;
    max-width: 300px;
  }
`;

const Top = styled.div`
    width: 100%;
    display: flex;
    gap: 12px
`

const Image = styled.img`
    height: 50px;
    margin-top: 4px;
    @media only screen and (max-width: 768px){
        height: 40px;
    }
`

const Body = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column; 
`


const Name = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.text_primary};
    @media only screen and (max-width: 768px){
        font-size: 14px;
    }
`

const Degree = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.text_secondary};
    @media only screen and (max-width: 768px){
        font-size: 12px;
    }
`

const Date = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.text_secondary};
    @media only screen and (max-width: 768px){
        font-size: 10px;
    }
`

const Grade = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.text_secondary};
    @media only screen and (max-width: 768px){
        font-size: 12px;
    }
`



const EducationCard = ({ education }) => {

    const cardRef = React.useRef(null);
    const { scrollYProgress } = useScroll({
        target: cardRef,
        offset: ["start end", "end start"]
    });

    const opacity = useTransform(
        scrollYProgress,
        [0, 0.3, 0.7, 1],
        [0, 1, 1, 0]
    );

    const x = useTransform(
        scrollYProgress,
        [0, 0.3, 0.7, 1],
        [200, 0, 0, 0]
    );

    return (
        <motion.div style={{ opacity, x: x }}>
            <Card
                ref={cardRef}
                whileHover={{
                    boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
                    y: -5,
                    transition: { duration: 0.3, ease: 'easeInOut' }
                }}
            >
                <Top>
                    <Image src={education.img} />
                    <Body>
                        <Name>{education.school}</Name>
                        <Degree>{education.degree}</Degree>
                        <Date>{education.date}</Date>
                    </Body>
                </Top>
                <Grade><b>Grade: </b>{education.grade}</Grade>
                <Description>
                    <Span>{education.desc}</Span>
                </Description>
            </Card>
        </motion.div>
    )
}

export default EducationCard