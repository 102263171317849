import React from "react";
import styled, { useTheme } from "styled-components";
import { Profile } from "../../data/constants";
import { LinkedinOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";

const FooterContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const FooterWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  padding: 1rem;
  color: ${({ theme }) => theme.text_primary};
`;

const Logo = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: ${({ theme }) => theme.primary};
`;

const Nav = styled.nav`
  width: 100%;
  max-width: 800px;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 12px;
  }
`;

const NavLink = styled(motion.a)`
  color: ${({ theme }) => theme.text_primary};
  text-decoration: none;
  font-size: 1.2rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  margin-top: 1rem;
`;
const SocialMediaIcon = styled.a`
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text_primary};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const Copyright = styled.p`
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.soft2};
  text-align: center;
`;

const Footer = () => {

  const theme = useTheme();

  return (
    <FooterContainer>
      <FooterWrapper>
        <Logo>Kenneth Fung</Logo>
        <Nav>
          <NavLink whileHover={{
                            color: theme.primary,
                            scale: 1.05,
                            y: 10
                        }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ duration: 0.3 }} href="#about">About</NavLink>
          <NavLink whileHover={{
                            color: theme.primary,
                            scale: 1.05,
                            y: 10
                        }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ duration: 0.3 }} href="#skills">Skills</NavLink>
          <NavLink whileHover={{
                            color: theme.primary,
                            scale: 1.05,
                            y: 10
                        }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ duration: 0.3 }} href="#experience">Experience</NavLink>
          <NavLink whileHover={{
                            color: theme.primary,
                            scale: 1.05,
                            y: 10
                        }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ duration: 0.3 }} href="#projects">Projects</NavLink>
          <NavLink whileHover={{
                            color: theme.primary,
                            scale: 1.05,
                            y: 10
                        }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ duration: 0.3 }} href="#education">Education</NavLink>
          <NavLink whileHover={{
                            color: theme.primary,
                            scale: 1.05,
                            y: 10
                        }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ duration: 0.3 }} href="#contact">Contact</NavLink>
        </Nav>
        <SocialMediaIcons>
          <SocialMediaIcon href={Profile.linkedin} target="display">
            <LinkedinOutlined />
          </SocialMediaIcon>
        </SocialMediaIcons>
        <Copyright>&copy; 2024 Kenneth Fung. All rights reserved.</Copyright>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;