import styled, { css } from 'styled-components'

const staticStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.section`
  ${staticStyles}
  flex-direction: column;
  background: linear-gradient(343.07deg, rgba(240, 174, 31, 0.2) 5.71%, rgba(167, 227, 41, 0) 64.83%);
  position: relative;
  z-index: 1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 97%, 0 100%);

  @media (max-width: 960px){
    padding: 0px 0px 50px 0px;
  }
`;

const Wrapper = styled.div`
  ${staticStyles}
  flex-direction: column;
  width: 100%;
  max-width: 1350px;
  padding: 10px 20px 100px;
  gap: 12px;

  @media (max-width: 960px) {
    padding: 10px 15px 80px;
  }
`;

const Title = styled.h2`
  font-size: clamp(32px, 5vw, 42px);
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  transition: font-size 0.3s ease;
`;

const Description = styled.p`
  font-size: clamp(16px, 3vw, 18px);
  max-width: 600px;
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text_primary};
  transition: font-size 0.3s ease;

  @media (max-width: 768px) {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

const CardContainer = styled.div`
  ${staticStyles}
  flex-wrap: wrap;
  gap: 28px;
  width: 100%;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;

export { Container, Wrapper, Title, Description, CardContainer }