import React from 'react'
import styled from 'styled-components'
import { education } from '../../data/constants'
import { Timeline } from 'antd';
import EducationCard from '../cards/EducationCard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  padding: 40px 0px 40px 0px;

  @media (max-width: 960px){
    padding: 0px;
  }
`;

const Wrapper = styled.div`
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 12px;
`;

const Title = styled.div`
  font-size: 42px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  
  @media screen and (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  max-width: 600px;
  text-align: center;
  color: ${({ theme }) => theme.text_primary};

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const TimeLineSection = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-top: 10px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`

const Education = () => {
  return (
    <Container id="education">
      <Wrapper>
        <Title>
          Education
        </Title>
        <Description>
          My academic journey has been a stepping stone towards my goal of being a successful software developer. Explore my educational background.
        </Description>
        <TimeLineSection>
          <Timeline items={education.map((education) => {
            return {
              color: '#e88438',
              children: <EducationCard education={education} />
            }
          })} />
        </TimeLineSection>
      </Wrapper>
    </Container>
  )
}

export default Education