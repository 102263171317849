import React from 'react'
import styled from 'styled-components'
import { Profile } from "../../data/constants"
import TypewriterComponent from 'typewriter-effect';
import profileImg from '../../assets/portfolio_pic_v5.png'
import resumePDF from '../../assets/Resume_KennethFung.pdf'
import { motion } from "framer-motion";

const HeroContainer = styled.div`
  background-color: ${({ theme }) => theme.card_light};
  display: flex;
  justify-content: center;
  position: relative;
  padding: 80px 30px 80px 30px;

  @media screen and (max-width: 960px) {
    padding: 60px 16px;
  }

  @media screen and (max-width: 640px) {
    padding: 32px 16px;
  }

  z-index: 1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 70% 95%, 50% 100%, 30% 95%, 0 100%);
`;

const HeroInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1100px;

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

const HeroLeftContainer = styled.div`
  width: 100%;
  order: 1;
  @media screen and (max-width: 960px) {
    order: 2;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @media screen and (max-width: 640px) {
    order: 2;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const HeroRightContainer = styled.div`
  width: 100%;
  order: 2;
  display: flex;
  justify-content: end;
  gap: 12px;

  @media screen and (max-width: 960px) {
    order: 1;
    margin-bottom: 80px;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 640px) {
    order: 1;
    margin-bottom: 30px;
  }
`;

const Title = styled.div`
  font-size: 50px;
  font-0weight: 700;
  color: ${({ theme }) => theme.text_primary};
  line-height: 60px;

  @media screen and (max-width: 960px){
    text-align: center
  }

  @media screen and (max-width: 640px){
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 8px;
  }
`;

const Name = styled.span`
  color: ${({ theme }) => theme.text_secondary}
`

const TextLoop = styled.div`
  font-size: 32px;
  font-weight: 600px;
  color: ${({ theme }) => theme.text_primary};
  line-height: 68px;
  gap: 12px;
  display: flex;

  @media screen and (max-width: 960px){
    text-align: center
  }

  @media screen and (max-width: 640px){
    font-size: 22px;
    line-height: 48px;
    margin-bottom: 16px;
  }
`;

const Span = styled.span`
  color: ${({ theme }) => theme.primary};
  cursor: pointer;
`;

const SubTitle = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.text_primary};
  line-height: 32px;
  margin-bottom: 42px;

  @media screen and (max-width: 960px) {
    text-align: center;
  }

  @media screen and (max-width: 640px) {
    font-size: 16px;
    line-height: 32px;
  }
`;

const ResumeBtn = styled(motion.a)`
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  text-decoration: none;
  width: 95%;
  max-width: 300px;
  text-align: center;
  padding: 16px 0;
  color:${({ theme }) => theme.text_primary};
  border-radius: 20px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  background: hsla(26, 26%, 89%, 1);
  background: linear-gradient(225deg, hsla(26, 26%, 89%, 1) 0%, hsla(26, 100%, 68%, 1) 100%);
  background: -moz-linear-gradient(225deg, hsla(26, 26%, 89%, 1) 0%, hsla(26, 100%, 68%, 1) 100%);
  background: -webkit-linear-gradient(225deg, hsla(26, 26%, 89%, 1) 0%, hsla(26, 100%, 68%, 1) 100%);
  box-shadow:  10px 10px 40px #999797;

  @media (max-width: 640px) {
      padding: 12px 0;
      font-size: 18px;
  } 
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  max-width: 500px;
  max-height: 500px;
  background-color: ${({ theme }) => theme.primary + 30};
  object-fit: contain;
  object-position: center;
  border: 2px solid ${({ theme }) => theme.primary};

  @media screen and (max-width: 768px) {
    max-width: 400px;
    max-height: 400px;
  }

  @media screen and (max-width: 640px) {
    max-height: 200px;
    max-width: 200px;
  }
`

const Hero = () => {
  return (
    <div id='about'>
      <HeroContainer>
        <HeroInnerContainer>
          <HeroLeftContainer>
            <Title>Hi, I am <br />
              <Name>{Profile.name}</Name>
            </Title>
            <TextLoop>
              A
              <Span>
                <TypewriterComponent
                  options={{
                    strings: Profile.roles,
                    autoStart: true,
                    loop: true
                  }}
                />
              </Span>
            </TextLoop>
            <SubTitle>{Profile.description}</SubTitle>
            <ResumeBtn
              whileHover={{
                y: 0, scale: 1.1,
                transition: {
                  duration: 0.8
                }
              }}
              whileTap={{
                y: 0, scale: 0.8,
                transition: {
                  duration: 0.8
                }
              }}
              animate={{
                y: [0, -3, -5, -3, 0],
                transition: {
                  repeat: Infinity,
                  duration: 1.5
                }
              }}
              href={resumePDF} download="Resume_KennethFung" target='_blank'>Get My Resume</ResumeBtn>
          </HeroLeftContainer>
          <HeroRightContainer>
            <Image src={profileImg} alt="Hero" />
          </HeroRightContainer>
        </HeroInnerContainer>
      </HeroContainer>
    </div>
  )
}

export default Hero